<style lang="scss">
#book-footer-temp {
  .btn {
    height: 40px !important;
  }
}
</style>
<template>

  <div
    id="book-footer-temp"
    class="bottom-nav"
    style="position: fixed; bottom: 0; width: calc(100vw)"
  >
    <div class="card">
      <div class="card-body">
        <div class="cleft" v-if="totalamount != ''">
          <div
            class="total_amount"
            v-if="true"
          >
            <label for="">
              <small>Total</small>
            </label>
            <h2 v-if="!isMobile">${{ totalamount }}</h2>
            <h3 v-else class="footer-total-amount">${{ totalamount }}</h3>
          </div>
        </div>
        <div :class="isMobile ? 'mt-3' : 'mt-4'">
          <button
          :disabled="getWizardFormButtonDisable"
            class="btn btn-success btn-right"
            :id="[btnname == 'Submit' ? 'drugsubmit' : '']"
            @click.prevent="next()"
            style="width:115px"
            ref="kt_submit"
          >
            {{ btnname }}
            <i style="margin-left: 5px;" :class="'fa ' + nextbtnicon"></i>
          </button>
          <button
            class="btn btn-dark mr-2 btn-right"
            @click.prevent="back()"
            v-if="backname != ''"
          >
            <i class="fa fa-arrow-left"></i> {{ backname }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import { mapGetters } from "vuex";

export default {
  props: {
    btnname: {
      type: String,
      default: "Button"
    },
    nextbtnicon: {
      type: String,
      default: "fa-arrow-right"
    },
    backname: {
      type: String,
      default: "Back"
    },
    totalamount: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isMobile: isMobile
    };
  },
  computed: {
    ...mapGetters(["getAbbreviationCode","getWizardFormButtonDisable"])
  },
  methods: {
    next() {
      this.$emit("next");
    },
    back() {
      this.$emit("back");
    }
  }
};
</script>
